import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    /* stylelint-disable-next-line */
    font: inherit;
    vertical-align: baseline;
  }

  /* Added to Fix Footer to bottom of viewport */
  html, body {
    height: 100%;
  }
  .siteRoot {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .siteContent {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
  footer {
    width: 100%;
  }

  /* End Fix to Place Footer on Bottom of Viewport */

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  @media screen and (min-width: 35em) {
    html {
      margin-right: calc(-100vw + 100%);
      overflow-x: hidden;
    }
  }

  ol, ul, li {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  body {
    background: white;
    line-height: 1;
    font-size: 100%;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  img {
    display: block;
  	width: 100%;
  	height: auto;
  }

  button,
  input {
    font-family: inherit;
    font-size: inherit;
    background: none;
    border: none;
    outline: none;
    appearance: none;
    border-radius: 0;
    resize: none;
    &:focus {
      outline: none;
    }
    &:invalid {
      box-shadow: none;
    }
  }

  .divider {
    margin: 8px 0 !important;
    max-width: 95%;
  }

  .certheader {
    margin: 0 0 15px;
  }

/* Timeline styling */
  .timeline {
    margin: 40px 0 0 !important;
    max-width: 100% !important;
  }

  .timeline--wrapper {
    width: auto !important;
    margin-top: 55px;
    padding: 0 !important;
  }

  .timeline h3 {
    font-weight: bold;
    margin-bottom: 5px !important;
  }

  .timeline h4 {
    font-weight: italic !important;
    margin-bottom: 12px !important;
  }

  @media only screen and (max-width: 768px) {
    .timeline-item-date {
      width: 190px !important;
      /* -webkit-clip-path: polygon(0% 0%, 95% 0%, 105% 50%, 95% 100%, 0% 100%) !important; */
      clip-path: polygon(0% 0%, 95% 0%, 105% 50%, 95% 100%, 0% 100%) !important;
    }
    .timeline-item-dateinner {
      /* -webkit-clip-path: polygon(0% 0%, 95% 0%, 105% 50%, 95% 100%, 0% 100%) !important; */
      clip-path: polygon(0% 0%, 95% 0%, 105% 50%, 95% 100%, 0% 100%) !important;
      width: 110% !important;
    }
  }

  button.hovertext,
#gatsby-focus-wrapper > div > div > section > div > div:nth-child(1) > div > div > button {
  padding: 0 !important;
  color: var(--primary) !important;
}

#gatsby-focus-wrapper > div > div > section > div > div:nth-child(1) > div > p:nth-child(5) > div > div > div {
  width: 250px;
}

`
export default GlobalStyle
