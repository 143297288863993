import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Header = styled.header`
  background: ${props => props.theme.colors.base};
  width: 100%;
  padding: 1.5em 0;
`
const Nav = styled.nav`
  max-width: 1047px;
  min-width: 380px;
  margin: 0 auto;
  padding: 0 1.5em;

  ul {
    display: inline-block;
    justify-content: space-between;
    max-width: 400px;
    float: right;
  }

  li {
    display: inline-block;
    margin-left: 35px;
    &:first-child {
      position: relative;
      margin: 0;
    }
  }

  a {
    text-decoration: none;
    color: DarkGray;
    font-weight: 600;
    transition: all 0.2s;
    border-bottom: 2px solid ${props => props.theme.colors.base};
    &:hover {
      color: white;
    }
  }

  .logo {
    display: inline-block;
    color: white;
  }
`

const activeLinkStyle = {
  color: 'white',
}

const Menu = () => {
  return (
    <Header>
      <Nav>
      <div className="logo">
      <a href="/">Erik Reiblein</a>
      </div>
        <ul>
          <li>
            <Link to="/" activeStyle={activeLinkStyle}>
              Work
            </Link>
          </li>
          <li>
            <Link to="/about/" activeStyle={activeLinkStyle}>
              About
            </Link>
          </li>
          <li>
            <Link to="/contact/" activeStyle={activeLinkStyle}>
              Contact
            </Link>
          </li>
        </ul>
      </Nav>
    </Header>
  )
}

export default Menu
